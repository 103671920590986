.page-main {
  padding: 0; }

.columns .column.main {
  padding: 0; }

[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  max-width: 100%; }

.home-slider {
  position: relative;
  z-index: 0; }
  @media (min-width: 768px) {
    .home-slider {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto; } }

@media (max-width: 767.98px) {
  .cms-index-index .message.message:not(:empty) {
    margin-top: 8.5rem; } }

.pagebuilder-slider {
  visibility: visible !important;
  position: relative;
  background-color: transparent;
  padding: 0 0 4.2rem;
  margin: 0; }
  .pagebuilder-slider .pagebuilder-slide-wrapper {
    height: 320px; }
    @media (max-width: 767.98px) {
      .pagebuilder-slider .pagebuilder-slide-wrapper {
        display: flex;
        align-items: flex-end;
        height: 430px; } }
    .pagebuilder-slider .pagebuilder-slide-wrapper [data-element='content'] {
      min-height: auto !important; }
    .pagebuilder-slider .pagebuilder-slide-wrapper::before {
      position: absolute;
      pointer-events: none;
      background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%) no-repeat padding-box;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      z-index: 1;
      opacity: 1; }
      @media (max-width: 767.98px) {
        .pagebuilder-slider .pagebuilder-slide-wrapper::before {
          content: ''; } }
    .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-poster-content {
      max-width: 1600px; }
      @media (max-width: 767.98px) {
        .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-poster-content {
          text-align: center; } }
    .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-overlay {
      width: 100%;
      height: 260px;
      padding: 0 11.4rem; }
      @media (max-width: 767.98px) {
        .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-overlay {
          align-items: flex-end;
          padding: 0 7rem 9rem;
          z-index: 2; } }
    .pagebuilder-slider .pagebuilder-slide-wrapper p {
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 1; }
      @media (max-width: 767.98px) {
        .pagebuilder-slider .pagebuilder-slide-wrapper p {
          line-height: 1.53;
          margin: 0 2rem; } }
    .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-button-primary, .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-button-secondary {
      margin-top: 1.3rem;
      border: 2px solid #fff; }
      @media (max-width: 767.98px) {
        .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-button-primary, .pagebuilder-slider .pagebuilder-slide-wrapper .pagebuilder-button-secondary {
          margin-top: 2rem;
          padding: 1.3rem 3.6rem;
          line-height: 1.4rem; } }

.slick-slider .slick-dots {
  position: absolute;
  bottom: 15px;
  padding: 0;
  line-height: 1.2rem; }
  .slick-slider .slick-dots li {
    width: auto;
    height: 100%; }
    .slick-slider .slick-dots li button {
      border: none;
      background-color: rgba(144, 144, 144, 0.4);
      transition: width 0.4s;
      width: 12px;
      height: 12px; }
      .slick-slider .slick-dots li button:hover {
        border: none; }
    .slick-slider .slick-dots li.slick-active button {
      background-color: #EF4135;
      width: 24px; }

.slick-slider .slick-prev,
.slick-slider .slick-next {
  background: #000 !important;
  padding: 0;
  width: 44px;
  height: 44px;
  border-radius: 2.5rem;
  border: 2px solid #fff;
  transform: translateY(-4.3rem); }
  .slick-slider .slick-prev::before,
  .slick-slider .slick-next::before {
    content: '';
    position: absolute;
    background-image: url("../images/icon-arrow-white.svg");
    background-size: 14px 16px;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .slick-slider .slick-prev:hover,
  .slick-slider .slick-next:hover {
    background: #EF4135 !important; }
  .slick-slider .slick-prev.slick-disabled,
  .slick-slider .slick-next.slick-disabled {
    display: none !important; }

.slick-slider .slick-next {
  right: 4rem; }
  @media (max-width: 767.98px) {
    .slick-slider .slick-next {
      right: 2.6rem; } }

.slick-slider .slick-prev {
  left: 4rem; }
  @media (max-width: 767.98px) {
    .slick-slider .slick-prev {
      left: 2.6rem; } }
  .slick-slider .slick-prev::before {
    transform: rotate(180deg); }

.parts-catalogue-container {
  padding: 6.5rem 2.8rem 3.5rem;
  position: relative; }
  .parts-catalogue-container .pagebuilder-column-group {
    max-width: 1335px;
    margin: auto; }
  .parts-catalogue-container h3 {
    font-size: 2.5rem;
    margin: 1.7rem auto 5.7rem;
    max-width: 250px;
    color: #000; }
    @media (min-width: 768px) {
      .parts-catalogue-container h3 {
        font-size: 3.2rem;
        max-width: 490px;
        margin: 2.1rem auto 5rem; } }
  .parts-catalogue-container h4 {
    margin-bottom: 0;
    text-transform: uppercase; }
  .parts-catalogue-container .subtitle {
    color: #EF4135;
    font-size: 1.6rem;
    font-weight: 700; }
    @media (min-width: 768px) {
      .parts-catalogue-container .subtitle {
        font-size: 1.8rem; } }

.parts-catalogue .pagebuilder-column-line {
  gap: 1.5rem;
  flex-wrap: wrap; }
  @media (min-width: 1024px) {
    .parts-catalogue .pagebuilder-column-line {
      gap: 4rem; } }

.parts-catalogue .pagebuilder-column {
  position: relative;
  z-index: 0;
  flex: 1 1 40%;
  background: #F2F2F2;
  border: 4px solid #F2F2F2;
  border-radius: 4px;
  min-height: 172px;
  justify-content: flex-end; }
  .parts-catalogue .pagebuilder-column [data-content-type='image'] {
    padding: 0 1rem; }
    .parts-catalogue .pagebuilder-column [data-content-type='image'] img {
      max-height: 100%; }
  .parts-catalogue .pagebuilder-column [data-content-type='text'] {
    margin-bottom: 1.5rem; }
    .parts-catalogue .pagebuilder-column [data-content-type='text'] a {
      font-size: 1.4rem;
      color: #231F20;
      font-weight: 700; }
      .parts-catalogue .pagebuilder-column [data-content-type='text'] a::before {
        content: '';
        position: absolute;
        inset: -4px;
        border: 4px solid #F2F2F2;
        border-radius: 4px;
        z-index: 1; }
      .parts-catalogue .pagebuilder-column [data-content-type='text'] a:hover::before {
        border-color: #EF4135; }
  @media (min-width: 768px) {
    .parts-catalogue .pagebuilder-column {
      flex: 1 1 calc(20% - 15px); } }
  @media (min-width: 1024px) {
    .parts-catalogue .pagebuilder-column {
      flex-basis: calc(20% - 40px);
      min-height: 235px; }
      .parts-catalogue .pagebuilder-column [data-content-type='image'] {
        padding: 0 2.5rem; }
      .parts-catalogue .pagebuilder-column [data-content-type='text'] {
        margin-bottom: 3rem; }
        .parts-catalogue .pagebuilder-column [data-content-type='text'] a {
          font-size: 2rem; } }

@media (min-width: 768px) {
  .better-journeys-container {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 768px) {
  .better-journeys-container .pagebuilder-poster-overlay {
    margin-top: -9rem;
    padding: 0 0 61.274%; }
    .better-journeys-container .pagebuilder-poster-overlay [data-element='content'] {
      min-height: 0; } }

.better-journeys-container figure {
  margin-bottom: 0;
  text-align: center; }
  @media (min-width: 768px) {
    .better-journeys-container figure {
      margin-top: -9rem; } }

.homepage-banner,
.promo-banner-bottom-container {
  margin-top: -0.1rem;
  margin-bottom: -0.1rem; }
  @media (min-width: 768px) {
    .homepage-banner,
    .promo-banner-bottom-container {
      max-width: 1570px;
      margin-left: auto;
      margin-right: auto; } }
  .homepage-banner .pagebuilder-banner-wrapper,
  .promo-banner-bottom-container .pagebuilder-banner-wrapper {
    display: flex; }
    .homepage-banner .pagebuilder-banner-wrapper .pagebuilder-overlay,
    .promo-banner-bottom-container .pagebuilder-banner-wrapper .pagebuilder-overlay {
      margin: auto; }
      @media (max-width: 767.98px) {
        .homepage-banner .pagebuilder-banner-wrapper .pagebuilder-overlay,
        .promo-banner-bottom-container .pagebuilder-banner-wrapper .pagebuilder-overlay {
          padding-inline: 2rem; } }
      .homepage-banner .pagebuilder-banner-wrapper .pagebuilder-overlay p,
      .promo-banner-bottom-container .pagebuilder-banner-wrapper .pagebuilder-overlay p {
        line-height: 1; }
        @media (max-width: 767.98px) {
          .homepage-banner .pagebuilder-banner-wrapper .pagebuilder-overlay p,
          .promo-banner-bottom-container .pagebuilder-banner-wrapper .pagebuilder-overlay p {
            max-width: 320px; } }
      .homepage-banner .pagebuilder-banner-wrapper .pagebuilder-overlay a[title='link button'],
      .promo-banner-bottom-container .pagebuilder-banner-wrapper .pagebuilder-overlay a[title='link button'] {
        font-size: 1.4rem;
        font-weight: 700;
        height: 44px;
        border-radius: 100px;
        width: 158px;
        max-width: 100%;
        margin: 2rem auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background: #EF4135;
        color: #fff; }
        @media (max-width: 767.98px) {
          .homepage-banner .pagebuilder-banner-wrapper .pagebuilder-overlay a[title='link button'],
          .promo-banner-bottom-container .pagebuilder-banner-wrapper .pagebuilder-overlay a[title='link button'] {
            margin-top: 2.9rem;
            margin-bottom: 2.5rem; } }
    @media (min-width: 1024px) {
      .homepage-banner .pagebuilder-banner-wrapper,
      .promo-banner-bottom-container .pagebuilder-banner-wrapper {
        min-height: 220px !important; }
        .homepage-banner .pagebuilder-banner-wrapper p,
        .promo-banner-bottom-container .pagebuilder-banner-wrapper p {
          margin-bottom: 0; } }
  .homepage-banner .pedders-buying-guide,
  .promo-banner-bottom-container .pedders-buying-guide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }
    .homepage-banner .pedders-buying-guide h2,
    .promo-banner-bottom-container .pedders-buying-guide h2 {
      text-transform: capitalize;
      font-size: 4rem;
      color: #fff;
      margin-bottom: 1rem; }
      @media (max-width: 1023.98px) {
        .homepage-banner .pedders-buying-guide h2,
        .promo-banner-bottom-container .pedders-buying-guide h2 {
          font-size: 3.2rem;
          line-height: 1.31; } }
    .homepage-banner .pedders-buying-guide p,
    .promo-banner-bottom-container .pedders-buying-guide p {
      line-height: 1.61;
      max-width: 46.7rem;
      margin: 0 auto;
      color: #fff;
      font-size: 1.8rem; }
      @media (max-width: 1023.98px) {
        .homepage-banner .pedders-buying-guide p,
        .promo-banner-bottom-container .pedders-buying-guide p {
          max-width: 26.2rem; } }
    .homepage-banner .pedders-buying-guide a[title='link button'],
    .promo-banner-bottom-container .pedders-buying-guide a[title='link button'] {
      font-size: 1.4rem;
      font-weight: 700;
      height: 4.4rem;
      border-radius: 100px;
      width: 15.8rem;
      max-width: 100%;
      margin: 2rem auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      background: #EF4135;
      color: #fff; }
      @media (max-width: 767.98px) {
        .homepage-banner .pedders-buying-guide a[title='link button'],
        .promo-banner-bottom-container .pedders-buying-guide a[title='link button'] {
          margin-top: 2.9rem;
          margin-bottom: 2.5rem; } }

.buying-guide-banner-container {
  position: relative;
  min-height: 320px !important;
  margin-bottom: 0; }
  .buying-guide-banner-container::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); }
  .buying-guide-banner-container .pagebuilder-banner-wrapper {
    min-height: 320px !important; }
    .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: 70rem; }
    .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-overlay a[title='link button'] {
      width: 22.9rem;
      margin-top: 0; }
      @media (max-width: 1023.98px) {
        .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-overlay a[title='link button'] {
          margin-top: 1.5rem;
          margin-bottom: 0; } }
    .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-collage-content > [data-element='content'] {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      color: #fff; }
      .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-collage-content > [data-element='content'] p:first-child {
        text-transform: capitalize;
        font-size: 4rem; }
      .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-collage-content > [data-element='content'] p:last-child {
        margin-top: -1rem; }
      .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-collage-content > [data-element='content'] p:not(:first-child) {
        line-height: 1.61;
        max-width: 467px;
        margin: 0 auto; }
      @media (max-width: 1023.98px) {
        .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-collage-content > [data-element='content'] {
          display: block; }
          .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-collage-content > [data-element='content'] p:first-child {
            font-size: 3.2rem;
            line-height: 1.31; }
          .buying-guide-banner-container .pagebuilder-banner-wrapper .pagebuilder-collage-content > [data-element='content'] p:not(:first-child) {
            max-width: 262px; } }
    @media (max-width: 1023.98px) {
      .buying-guide-banner-container .pagebuilder-banner-wrapper {
        min-height: 350px !important; } }
  .buying-guide-banner-container .pedders-buying-guide a[title='link button'] {
    width: 22.9rem;
    margin-top: 1.5rem; }
    @media (max-width: 1023.98px) {
      .buying-guide-banner-container .pedders-buying-guide a[title='link button'] {
        margin-top: 1.5rem;
        margin-bottom: 0; } }
