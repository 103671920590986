.better-journeys-container {

  @include media-breakpoint-up(md) {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  .pagebuilder-poster-overlay {

    @include media-breakpoint-up(md) {
      margin-top: -9rem;
      padding: 0 0 61.274%;

      [data-element='content'] {
        min-height: 0;
      }
    }
  }

  figure {
    margin-bottom: 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: -9rem;
    }
  }
}
