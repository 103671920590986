.parts-catalogue-container {
  padding: 6.5rem 2.8rem 3.5rem;
  position: relative;

  .pagebuilder-column-group {
    max-width: 1335px;
    margin: auto;
  }

  h3 {
    font-size: 2.5rem;
    margin: 1.7rem auto 5.7rem;
    max-width: 250px;
    color: $black;

    @include media-breakpoint-up(md) {
      font-size: 3.2rem;
      max-width: 490px;
      margin: 2.1rem auto 5rem;
    }
  }

  h4 {
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .subtitle {
    color: $primary;
    font-size: 1.6rem;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
    }
  }
}

.parts-catalogue {

  .pagebuilder-column-line {
    gap: 1.5rem;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      gap: 4rem;
    }
  }

  .pagebuilder-column {
    position: relative;
    z-index: 0;
    flex: 1 1 40%;
    background: $gray-50;
    border: 4px solid $gray-50;
    border-radius: 4px;
    min-height: 172px;
    justify-content: flex-end;

    [data-content-type='image'] {
      padding: 0 1rem;

      img {
        max-height: 100%;
      }
    }

    [data-content-type='text'] {
      margin-bottom: 1.5rem;

      a {
        font-size: 1.4rem;
        color: $body-color;
        font-weight: 700;

        &::before {
          content: '';
          position: absolute;
          inset: -4px;
          border: 4px solid $gray-50;
          border-radius: 4px;
          z-index: 1;
        }

        &:hover::before {
          border-color: $primary;
        }
      }
    }

    @include media-breakpoint-up(md) {
      flex: 1 1 calc(20% - 15px);
    }

    @include media-breakpoint-up(lg) {
      flex-basis: calc(20% - 40px);
      min-height: 235px;

      [data-content-type='image'] {
        padding: 0 2.5rem;
      }

      [data-content-type='text'] {
        margin-bottom: 3rem;

        a {
          font-size: 2rem;
        }
      }
    }
  }
}
