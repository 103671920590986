@import '../vendors/bootstrap/functions';
@import '../variables';
@import '../vendors/bootstrap/mixins';

.page-main {
  padding: 0;
}

.columns .column.main {
  padding: 0;
}

[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  max-width: 100%;
}

.home-slider {
  position: relative;
  z-index: 0;

  @include media-breakpoint-up(md) {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

@include media-breakpoint-down(md) {

  .cms-index-index .message.message:not(:empty) {
    margin-top: 8.5rem;
  }
}

@import '../components/home/slider';
@import '../components/parts-catalogue';
@import '../components/home/better-journeys';
@import '../components/home/promo-banner-bottom';
@import '../components/home/buying-guide-banner';
