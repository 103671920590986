.homepage-banner,
.promo-banner-bottom-container {
  margin-top: -0.1rem;
  margin-bottom: -0.1rem;

  @include media-breakpoint-up(md) {
    max-width: 1570px;
    margin-left: auto;
    margin-right: auto;
  }

  .pagebuilder-banner-wrapper {
    display: flex;

    .pagebuilder-overlay {
      margin: auto;

      @include media-breakpoint-down(md) {
        padding-inline: 2rem;
      }

      p {
        line-height: 1;

        @include media-breakpoint-down(md) {
          max-width: 320px;
        }
      }

      a[title='link button'] {
        font-size: 1.4rem;
        font-weight: 700;
        height: 44px;
        border-radius: 100px;
        width: 158px;
        max-width: 100%;
        margin: 2rem auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background: $primary;
        color: $white;

        @include media-breakpoint-down(md) {
          margin-top: 2.9rem;
          margin-bottom: 2.5rem;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      min-height: 220px !important;

      p {
        margin-bottom: 0;
      }
    }
  }

  .pedders-buying-guide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h2 {
      text-transform: capitalize;
      font-size: 4rem;
      color: $white;
      margin-bottom: 1rem;

      @include media-breakpoint-down(lg) {
        font-size: 3.2rem;
        line-height: 1.31;
      }
    }

    p {
      line-height: 1.61;
      max-width: 46.7rem;
      margin: 0 auto;
      color: $white;
      font-size: 1.8rem;

      @include media-breakpoint-down(lg) {
        max-width: 26.2rem;
      }
    }

    a[title='link button'] {
      font-size: 1.4rem;
      font-weight: 700;
      height: 4.4rem;
      border-radius: 100px;
      width: 15.8rem;
      max-width: 100%;
      margin: 2rem auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      background: $primary;
      color: $white;

      @include media-breakpoint-down(md) {
        margin-top: 2.9rem;
        margin-bottom: 2.5rem;
      }
    }
  }
}
