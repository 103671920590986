@import '../../variables';

.buying-guide-banner-container {
  position: relative;
  min-height: 320px !important;
  margin-bottom: 0;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .pagebuilder-banner-wrapper {
    min-height: 320px !important;

    .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: 70rem;
    }

    .pagebuilder-overlay a[title='link button'] {
      width: 22.9rem;
      margin-top: 0;

      @include media-breakpoint-down(lg) {
        margin-top: 1.5rem;
        margin-bottom: 0;
      }
    }

    .pagebuilder-collage-content > [data-element='content'] {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      color: $white;

      p:first-child {
        text-transform: capitalize;
        font-size: 4rem;
      }

      p:last-child {
        margin-top: -1rem;
      }

      p:not(:first-child) {
        line-height: 1.61;
        max-width: 467px;
        margin: 0 auto;
      }

      @include media-breakpoint-down(lg) {
        display: block;

        p:first-child {
          font-size: 3.2rem;
          line-height: 1.31;
        }

        p:not(:first-child) {
          max-width: 262px;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      min-height: 350px !important;
    }
  }

  .pedders-buying-guide {

    a[title='link button'] {
      width: 22.9rem;
      margin-top: 1.5rem;

      @include media-breakpoint-down(lg) {
        margin-top: 1.5rem;
        margin-bottom: 0;
      }
    }
  }
}
